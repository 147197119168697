import React, {useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Auth0HistoryProvider} from '../context/Auth0HistoryProvider';
import App from './App';
import {Api} from '../services/auth/api';
import {useBootstrap} from '../services/auth/config';
import {FullLoader} from './Loader';
import {Alert} from '@mui/material';

const BootstrapConfigurations = () => {
    const {isSuccess, data, isPending: isLoading, isError, error} = useBootstrap();

    useEffect(() => {
        data &&
            Api.setServiceUrlMap(
                new Map([
                    [Api.services.AUTH, data.authBaseUrl],
                    [
                        Api.services.SPI,
                        process.env.NODE_ENV === 'development'
                            ? process.env.REACT_APP_SPI_SERVICE_URL || data.spiBaseUrl
                            : data.spiBaseUrl,
                    ],
                ])
            );
    }, [data]);

    if (isError) {
        // TODO something else
        return (
            <Alert severity="error">{`Unable to bootstrap application properties: ${error.message}`}</Alert>
        );
    }

    if (!isSuccess || isLoading) {
        return <FullLoader />;
    }

    return (
        <Router>
            <Auth0HistoryProvider
                domain={data.domain}
                clientId={data.clientId}
                audience={data.audience}
            >
                <App />
            </Auth0HistoryProvider>
        </Router>
    );
};

export default BootstrapConfigurations;
